import * as React from "react";
import "../styles/mlops.scss";
import "../styles/mlops-services.scss";
import Layout from "../components/layoutnew";
import { graphql } from "gatsby";
import { useMemo } from "react";
import HeroMl from "../components/ml/hero";
import Solution from "../components/ml/solution";
import About from "../components/mlop-services/aboutsec";
import Roadmap from "../components/mlop-services/roadmap";
import Faq from "../components/mlop-services/faq";
import SEO from "../components/seo";
import { useState } from "react";
const ServicePage = ({ data }) => {
  console.log(data);
  const [solution, setSolution] = useState("0");
  const getIndexData = (data) => {
    const values = data.allStrapiMlopService.edges[0].node;
    return values;
  };
  const getData = useMemo(() => getIndexData(data), [data]);
  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <>
        <SEO slug={getData.slug} locale={getData.locale} />
        <main>
          <HeroMl
            data={getData.herosection}
            locale={getData.locale}
            theme={getData.herotheme.url}
          />
          <About
            process={getData.aboutus.about1}
            about={getData.aboutus}
            aboutimg={getData.anboutimg}
            img1=""
            img2=""
          />
          <Solution locale={getData.locale} />
          <Roadmap
            locale={getData.locale}
            roadmapData={getData.roadmap.roadmapdata}
            button={getData.roadmap.button}
          />
          <About
            process={getData.aboutus.about2}
            about=" "
            aboutimg=""
            img1={getData.head3img}
            img2={getData.head4img}
          />
          <Faq locale={getData.locale} />
        </main>
      </>
    </Layout>
  );
};

export default ServicePage;

export const query = graphql`
  query {
    allStrapiMlopService {
      edges {
        node {
          roadmap {
            button
            roadmapdata {
              isMerged
              number
              title
              description {
                head
                bulletpts {
                  val
                }
              }
            }
          }

          herosection {
            btn
            description
            heading
            subHeading1
            subHeading2
          }
          herotheme {
            url
          }
          aboutus {
            aboutdes
            abouttitle
            about1 {
              descr1_1
              descr1_2
              descr1_3
              descr2_1
              descr2_2
              descr2_3
              heading1
              heading2
            }
            about2 {
              descr1_1
              descr1_2
              descr1_3
              descr1_4
              descr2_1
              descr2_2
              descr2_3
              descr2_4
              heading1
              heading2
            }
          }
          solution {
            solutionService {
              descr
              value
            }
          }
          locale
          slug
          anboutimg {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          head3img {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          head4img {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
