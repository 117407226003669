import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
// import BlogImage from "./BlogImage";
import { Link } from "gatsby";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { node } from "prop-types";
import cloudsvg from "../../images/cloud.svg";

const Solution = ({ locale }) => {
  function getRandomItems(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }

  const queryData = useStaticQuery(graphql`
    query {
      allStrapiSolutionService {
        edges {
          node {
            slug
            service_list {
              value
            }
            heroSection {
              des1
            }
            title
            locale
          }
        }
      }
      allStrapiMlop {
        edges {
          node {
            solutions {
              solutionService {
                descr
                value
              }
            }
          }
        }
      }
    }
  `);
  // const NoImage = queryData.NoImage
  const data = queryData.allStrapiSolutionService.edges;
  const soldata = queryData.allStrapiMlop.edges[0].node.solutions;
  // const serviceData = [
  //   'Service',
  //   ...queryData.allServices.edges
  //     ?.filter(node => node.node.locale === locale)
  //     ?.map(node => node.node.value)
  // ]

  const getData = data.filter((node) => node.node.locale === locale);
  // Array of all Cloud News
  const allNews = getData;
  console.log("allnews:", allNews);
  // State to trigger Load more
  const [selectedOption, setSelectedOption] = useState("");
  const emptyQuery = "";
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  });
  const handleChangeSelectedMainItem = (category) => {
    const filtered = allNews || [];
    const query = category;
    let filteredContent = [];

    if (selectedOption === category) {
      // setSelectedOption("");
      // setState({
      //   query: "",
      //   // filteredData: filtered,
      // });
    } else {
      setSelectedOption(category);

      // const filterListName = `${category.toLowerCase()}List`;
      const filterListName = category;
      filteredContent = filtered.filter((val) => {
        // const filterList = val.node[filterListName];
        const filterList = category === val.node.service_list?.value;
        return filterList && filterList !== "" && filterList !== undefined;
      });

      setState({
        query,
        filteredData: filteredContent,
      });
    }
  };

  const { filteredData, query } = state;
  const hasSearchResults = filteredData && query !== emptyQuery;
  const posts = hasSearchResults
    ? getRandomItems(filteredData, 8)
    : getRandomItems(allNews, 8);

  console.log(posts);
  return (
    <>
      <div className="home-solution">
        <div className="solution-container">
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xs-12">
              <div className="category-block">
                <div className="radio-box">
                  <ul>
                    {/* <li> */}
                    {soldata.solutionService.map((node, index) => (
                      <li
                        key={index}
                        className={
                          selectedOption === node.value ? "selected" : ""
                        }
                      >
                        <label>
                          <input
                            type="checkbox"
                            name={node.value}
                            checked={selectedOption === node.value}
                            onChange={() =>
                              handleChangeSelectedMainItem(node.value)
                            }
                            style={{ display: "none" }}
                          />
                          {node.value}
                        </label>
                      </li>
                    ))}
                    {/* </li> */}
                  </ul>
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="col-lg-8 col-md-8 col-xs-12">
              {posts && posts.length > 0 ? (
                <div className="tab_secml">
                  <Tabs>
                    <TabList>
                      <Tab>
                        <span className="campaign_tab_title" >
                          {posts[0].node.service_list?.value
                            ? posts[0].node.service_list.value
                            : "Services"}
                        </span>
                      </Tab>
                    </TabList>
                    <TabPanel className="react-tabs__tab-panel">
                      <div className="soldescr">
                        {soldata.solutionService
                          .filter(
                            (node) =>
                              node.value === posts[0]?.node?.service_list?.value
                          )
                          .map((node, index) => (
                            <div key={index}>{node.descr}</div>
                          ))}
                        <span>Explore More</span>
                      </div>

                      <div className="row">
                        <div className="col-md-6 ">
                          <div className="inside_tab_Data">
                            {/* <div className="list"> */}
                            {posts
                              .slice(0, Math.ceil(posts.length / 2))
                              .map(({ node }, index) => (
                                <div key={index} className="servicelisting">
                                  <div>
                                    <h3>
                                      <Link to={`/${node.slug}`}>
                                        {node.title}
                                      </Link>
                                    </h3>

                                    {node?.heroSection?.des1 && (
                                      <div>
                                        <p className="limited-text">{node.heroSection?.des1}</p>
                                        <img
                                          src={cloudsvg}
                                          alt="cloud"
                                          loading="lazy"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                         
                        </div>
                        {/* Second Column */}
                        <div className="col-md-6">
                          <div className="inside_tab_Data">
                            {/* <div className="servicelisting"> */}
                            {posts
                              .slice(Math.ceil(posts.length / 2))
                              .map(({ node }, index) => (
                                <div key={index} className="servicelisting">
                                  <div>
                                    <h3>
                                      <Link to={`/${node.slug}`}>
                                        {node.title}
                                      </Link>
                                    </h3>
                                    {node?.heroSection?.des1 && (
                                      <div>
                                        <p className="limited-text">{node.heroSection?.des1}</p>
                                        <img
                                          src={cloudsvg}
                                          alt="cloud"
                                          loading="lazy"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            {/* </div> */}
                          </div>
                        </div>
                    
                      </div>
                    </TabPanel>
                  
                  </Tabs>
                </div>
              ) : (
                <p>No Posts Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Solution;
