import React from "react";
import { Link } from "gatsby";
const Roadmap = ({ roadmapData, button }) => {
  return (
    <div className="roadmap-container">
      <div className="roadmap">
        <div className="roadmap-title-section">
          <h2 className="roadmap-title">
            DevOps Evolution: Your Opsio Roadmap to Success
          </h2>
        </div>
        <div className="roadmap-steps">
          {roadmapData.map((step, index) => (
            <div
            // className={`roadmap-step ${
            //   step.isMerged ? "roadmap-step--merged" : ""
            // }`}
            // key={index}
            >
              <div className="roadmap-step-number">{step.number}</div>
              <ul className="roadmap-step-content">
                <div
                  className={`roadmap-step-content ${
                    step.isMerged ? "roadmap-step-content--merged" : ""
                  }`}
                  key={index}
                >
                  <li className="roadmap-step-title">{step.title}</li>
                </div>

                {step.description && (
                  <>
                    {" "}
                    {/* Fragment to render both head and bulletpts */}
                    <li className="roadmap-step-description-head">
                      {step.description.head}
                    </li>
                    <ul className="roadmap-step-bulletpts">
                      {step.description.bulletpts.map((bullet, index) => (
                        <li key={index}>{bullet.val}</li>
                      ))}
                    </ul>
                  </>
                )}
              </ul>
            </div>
          ))}
        </div>
        <div className="opsBtnwrap d-flex justify-content-center align-items-center">
          <Link to={`/contact-us/`} className="opsBtnlink">
            <p> {button}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
