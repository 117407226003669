import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
// import BlogImage from "./BlogImage";
import { Link } from "gatsby";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { node } from "prop-types";
import cloudsvg from "../../images/cloud.svg";

const Solution = ({ locale }) => {
  function getRandomItems(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }

  const queryData = useStaticQuery(graphql`
    query {
      allStrapiMlopService {
        edges {
          node {
            faq {
              faq {
                descr1_1
                descr1_2
                descr1_3
                descr1_4
                value
              }
              heading
            }
            locale
          }
        }
      }
    }
  `);
  // const NoImage = queryData.NoImage
  const data = queryData.allStrapiMlopService.edges[0].node.faq;

  console.log(data);

  // Array of all Cloud News
  const allmlNews = data.faq;
  console.log("allmlnews:", allmlNews);
  // State to trigger Load more
  const [selectedOption, setSelectedOption] = useState("");

  // ... your existing imports and component setup ...

  const emptyQuery = "";
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  });
  const handleChangeSelectedMainItem = (category) => {
    const filtered = allmlNews || [];
    const query = category;
    let filteredContent = [];

    if (selectedOption === category) {
      setSelectedOption("");
      setState({
        query: "",
        filteredData: "",
      });
    } else {
      setSelectedOption(category);

      // const filterListName = `${category.toLowerCase()}List`;

      const filterListName = category;
      filteredContent = filtered.filter((val) => {
        // const filterList = val.node[filterListName];
        const filterList = category === val.value;
        return filterList && filterList !== "" && filterList !== undefined;
      });

      setState({
        query,
        filteredData: filteredContent,
      });
    }
  };

  const { filteredData, query } = state;
  const hasSearchResults = filteredData && query !== emptyQuery;
  const posts = hasSearchResults
    ? getRandomItems(filteredData, 1)
    : getRandomItems(allmlNews, 1);

  console.log("posts:", posts);
  return (
    <>
      <div className="home-solution-service">
        <div className="solution-container">
            <h1>{data.heading}</h1>
          <div className="row">
            <div className="col-md-5 col-lg-5 col-xs-12">
              <div className="category-block">
                
                <div className="radio-box">
                  <ul>
                    {/* <li> */}
                    {allmlNews.map((node, index) => {
                      return (
                        <li
                          key={index}
                          className={
                            selectedOption === node.value ? "selected" : ""
                          }
                        >
                          <div className="label-container">
                            <label>
                              <h5>{node.value}</h5>
                              <input
                                type="checkbox"
                                name={node.value}
                                checked={selectedOption === node.value}
                                onChange={() =>
                                  handleChangeSelectedMainItem(node.value)
                                }
                                // style={{ display: "none" }}
                              />
                            </label>
                          </div>
                        </li>
                      );
                    })}
                    {/* ))} */}
                    {/* </li> */}
                  </ul>
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="col-lg-6 col-md-6 col-xs-12">
              {posts && posts.length > 0 ? (
                <div className="tab_secml">
                  <Tabs>
                    <TabList>
                      <Tab>
                        <span className="campaign_tab_title">
                          {/* {posts[0].node.service_list?.value
                            ? posts[0].node.service_list.value
                            : "Services"} */}
                        </span>
                      </Tab>
                    </TabList>
                    <TabPanel className="react-tabs__tab-panel">
                      <div className="row">
                        <div className="inside_tab_Data">
                          {/* <div className="list"> */}
                          {posts.map((node, index) => (
                            <div key={index}>
                              <div>
                                <h3>
                                  {/* <Link to={`/${node.slug}`}> */}
                                  {node.value}
                                  {/* </Link> */}
                                </h3>

                                {node?.descr1_1 && (
                                  <div>
                                    <p>{node.descr1_1}</p>
                                    <p>{node.descr1_2}</p>
                                    <p>{node.descr1_3}</p>
                                    <p>{node.descr1_4}</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* </div> */}
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              ) : (
                <p>No Posts Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Solution;
