import React, { useEffect, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
const Aboutsec = ({  process, about,aboutimg, img1, img2 }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
        });
      },
      {
        threshold: 0.5,
      }
    );

    observer.observe(document.getElementById("processml"));

    return () => observer.disconnect();
  }, []);

  return (
    <>
    <div style={{ backgroundColor: 'white' }}  className="processml serviceHotspot " id="processml">
      <div className="containerwrap">
        <div className="prorcss_wrap">
          <div className="processLeftSection ">
            <div className="processTitleml">{process.heading1}</div>

            <div className="processDescriptionml">
              {process.descr1_1}
            </div>
             <GatsbyImage
                    image={getImage(img1?.localFile)}
                    alt="Service Image"
                    className="process-image"
                />
            <div className="processDescriptionml">
              {process.descr1_2}
            </div>
            <div className="processDescriptionml">
              {process.descr1_3}
            </div>
            <div className="processDescriptionml">
              {process.descr1_4 && process.descr1_4}
            </div>
          </div>
          <div className="processLeftSection">
            <div className="processTitleml">{process.heading1}</div>

            <div className="processDescriptionml">
              {process.descr2_1}
            </div>
            <div className="processDescriptionml">
              {process.descr2_2}
            </div>
           
            <div className="processDescriptionml">
              {process.descr2_3}
            </div>
              <GatsbyImage
                    image={getImage(img2?.localFile)}
                    alt="Service Image"
                    className="process-image"
                />
            <div className="processDescriptionml">{process.descr2_4 && process.descr2_4}</div>
            
          </div>
        </div>
      </div>
      </div>
      {about && about?.abouttitle &&
    <div className="processml serviceHotspot " id="processml">
      <div className="containerwrap">
        <div className="prorcss_wrap">
          <div className="processLeftSectionabout ">
            
            <div className="processTitleab">{about.abouttitle}</div>
         
            <div className="processDescription" >{about.aboutdes}</div>
         
          </div>
         
          <div className="processRightSectionaboutab">
            <div className="row margin-none">
              <div className=" padding-none">
               
                   <GatsbyImage
                    image={getImage(aboutimg.localFile)}
                    alt="Service Image"
                    className="enquire-image"
                />
              </div>
          
            </div>
          </div>
        </div>
      </div>
    </div>
    }   
    </>
  );
};

export default Aboutsec;
