import React from "react";
import { Link } from "gatsby";


// import "bootstrap/dist/css/bootstrap.min.css";
import '../../styles/mlops.scss';
// import microsoftpartner from '../../images/microsoft-partner.png';
import home_theme from "../../images/home_hero.svg";
//heroBackgroundNewPlatform.svg

function HeroMl({ data, locale, theme }) {
  return (
    <div
      className="productHeroSectionml w-full"
      id="heading"
      
      // style={{ backgroundImage: `url(${theme})` }}
      style={{ backgroundImage: `url(${home_theme})` }}
      
    >
    
    
      <div className="container">
        <h1>
          <span>{data.heading}</span>
          <span>{data.subHeading1}</span>
          {data.subHeading2}
        </h1>
        <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
        <div className="opsBtnwrap d-flex justify-content-center align-items-center">
          <Link to={`/contact-us/`} className="opsBtnlink">
            <p>{data.btn}</p>
          </Link>
        </div>
      </div>
 
     </div>
  );
}

export default HeroMl;
